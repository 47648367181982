<template>
    <b-card>
        <p>Ange vilka kolumner som ska ingå i dubblettberäkningen</p>
        <div
            class="d-flex align-items-center mt-6"
        >
            <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
            <input type="checkbox" name="" v-model="cols.firstname" />
            <span></span>
            </label>
            <span class="ml-3 cursor-pointer"
            >{{ $t('MEMBER.FIRSTNAME') }}</span
            >
        </div>
        <div
            class="d-flex align-items-center mt-6"
        >
            <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
            <input type="checkbox" name="" v-model="cols.lastname" />
            <span></span>
            </label>
            <span class="ml-3 cursor-pointer"
            >{{ $t('MEMBER.LASTNAME') }}</span
            >
        </div>
        <div
            class="d-flex align-items-center mt-6"
        >
            <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
            <input type="checkbox" name="" v-model="cols.email" />
            <span></span>
            </label>
            <span class="ml-3 cursor-pointer"
            >{{ $t('MEMBER.EMAIL') }}</span
            >
        </div>
        <div
            class="d-flex align-items-center mt-6"
        >
            <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
            <input type="checkbox" name="" v-model="cols.phone" />
            <span></span>
            </label>
            <span class="ml-3 cursor-pointer"
            >{{ $t('MEMBER.PHONE') }}</span
            >
        </div>
        <div
            class="d-flex align-items-center mt-6"
        >
            <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
            <input type="checkbox" name="" v-model="cols.in_personnr" />
            <span></span>
            </label>
            <span class="ml-3 cursor-pointer"
            >{{ $t('MEMBER.PERSONNR') }}</span
            >
        </div>
        <div
            class="d-flex align-items-center mt-6"
        >
            <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
            <input type="checkbox" name="" v-model="cols.public_id" />
            <span></span>
            </label>
            <span class="ml-3 cursor-pointer"
            >{{ $t('MEMBER.PUBLIC_ID') }}</span
            >
        </div>
        <b-button class="mt-8" type="submit" variant="primary" @click="save">{{ $t('COMMON.SAVE') }}</b-button>

    </b-card>

</template>


<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'DuplicatesSettings',
  components: {

  },
  emits: ['onSettingsUpdated'],
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods']),
  },
  mounted() {
    this.loadSettings();
  },
  data() {
    return {
      showConfirm: true,
      cols: {}
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {

      }
    },
  },
  methods: {

    save() {
      axios
        .put(`/company/settings/system`, { duplicates: this.cols })
        .then(res => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));

          this.$emit('onSettingsUpdated', this.cols);
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'))
        });
    },


    loadSettings() {
      const loader = this.$loading.show();

      axios
        .get(`/company/system`)
        .then(res => {
          this.cols = res.data.company_settings.duplicates;
          if (!this.cols) {
            this.cols = {};
          }

          loader && loader.hide();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta inställningar');
          loader && loader.hide();
        });
    },
  }
};
</script>
